import AssetSection from "../../components/AssetSection";
import Case from "../../components/Case";
import FullImageSection from "../../components/FullImageSection";
import RightSideHeader from "../../components/RightSideHeader";
import ScreenShotSection from "../../components/ScreenShotSection";
import SpecialText from "../../components/SpecialText";
import TwoColumnsSection from "../../components/TwoColumnsSection";

import styles from "./styles.module.css";

const Claro = () => (
  <Case title="Claro" type="Mobile app" category="Telecom" date="2019">
    <div className={styles.customSection}>
      <ScreenShotSection
        imagesContainerClassName={styles.threeScreenshotSection}
        images={[
          "https://d2ec2ifrsjdn48.cloudfront.net/claro/image_1.png",
          "https://d2ec2ifrsjdn48.cloudfront.net/claro/image_2.png",
          "https://d2ec2ifrsjdn48.cloudfront.net/claro/image_3.png",
        ]}
      />
      <SpecialText className={styles.bottomTextSpecial}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In
        2018, Claro—Brazil’s second-largest telecom provider—partnered with Work
        & Co to bring their new mobile experience to life. As part of the core
        team, I owned and managed significant flows such as the payment and
        digital customer service experience, account management, and settings. I
        played a crucial role in creating and updating high-fidelity prototypes
        used in usability tests, as well as creating and
        maintaining the project's styleguide and components library.
      </SpecialText>
    </div>
    <TwoColumnsSection
      headerSecondColumn={
        <RightSideHeader>
          <p>Framework</p>
          <p>
            Combining a scalable mobile framework with handy features, the app
            puts the use cases that drive the majority of logins front and
            center. Now, instead of navigating an exhaustive list of options,
            the new framework is oriented around the tasks that drove the vast
            majority of interactions: checking data consumption, financial
            management, and plan configuration.{" "}
          </p>
        </RightSideHeader>
      }
      secondColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/claro/video_1.mp4"
          assetType="video"
          bgColor="var(--bg-black-secondary)"
          centered
        />
      }
    />
    <FullImageSection
      bleed
      imgBleed
      asset="https://d2ec2ifrsjdn48.cloudfront.net/claro/video_2.mp4"
      assetType="video"
      bgColor="var(--bg-black-secondary)"
      topContent={
        <RightSideHeader>
          <p>Auto login</p>
          <p>
            One of our most important tasks was to increase login success rate.
            To achieve that, we implemented a seamless no-login experience – to
            validate your identity, all you'd need to do is have your chip and
            the cellular data turned on.{" "}
          </p>
        </RightSideHeader>
      }
      topPosition="right"
      maximizeMobile
    />
    <div className={styles.customAssetsSection}>
      <TwoColumnsSection
        headerSecondColumn={
          <RightSideHeader>
            <p>Buy more data</p>
            <p>
              In a country where most people have prepaid cell phone plans,
              customers value the ability to quickly log in and check their data
              balance. With respect for Claro's customers’ time and often tight
              budgets, our design prioritizes displaying the most crucial
              information at any given moment, whether it be the current bill
              total or a warning indicating a low data balance. In cases of
              diminishing data, we implemented a interface that allows customers
              to effortlessly purchase additional data when needed the most.{" "}
            </p>
          </RightSideHeader>
        }
        secondColumn={
          <AssetSection
            asset="https://d2ec2ifrsjdn48.cloudfront.net/claro/video_3.mp4"
            assetType="video"
            bgColor="#E3272F"
            border="0.5rem solid #000"
            centered
          />
        }
      />
      <ScreenShotSection
        imagesContainerClassName={styles.threeScreenshotSection}
        images={[
          "https://d2ec2ifrsjdn48.cloudfront.net/claro/image_6.png",
          "https://d2ec2ifrsjdn48.cloudfront.net/claro/image_5.png",
          "https://d2ec2ifrsjdn48.cloudfront.net/claro/image_4.png",
        ]}
      />
    </div>
    <TwoColumnsSection
      headerSecondColumn={
        <RightSideHeader>
          <p>Change invoice due date</p>
          <p>
            On the other hand, for users going with a postpaid plan, we wanted
            to make sure they can effortlessly keep track of things. One neat
            feature we fine-tuned was the option to tweak the due date on their
            invoices. It's all about giving users the freedom to manage their
            billing cycle with ease.{" "}
          </p>
        </RightSideHeader>
      }
      secondColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/claro/video_4.mp4"
          assetType="video"
          bgColor="#E3272F"
          border="0.5rem solid #000"
          centered
        />
      }
    />
    <TwoColumnsSection
      headerSecondColumn={
        <RightSideHeader>
          <p>Benefits</p>
          <p>
            Postpaid customers also engage in Claro's loyalty program by earning
            points with each invoice payment. These points can be redeemed for a
            variety of products and services. Additionally, they consistently
            access discounts from diverse partners and have the opportunity to
            participate in exclusive promotions. To enrich this experience, we
            designed a new interface that allows customers to conveniently view
            their accumulated points and choose from a range of benefits.{" "}
          </p>
        </RightSideHeader>
      }
      secondColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/claro/image_7.png"
          assetType="image"
          bgColor="var(--bg-black-secondary)"
          centered
        />
      }
    />
    <div className={styles.customAssetHeaderTextSection}>
      <TwoColumnsSection
        headerSecondColumn={
          <RightSideHeader>
            <p>Create a scalable experience</p>
            <p>
              Going forward, the company plans to integrate its TV and broadband
              products into the app as well, and that has been taken into
              account when designing - all new features, improvements, and
              integrations can be easily added to the current framework without
              much friction for their users.{" "}
            </p>
          </RightSideHeader>
        }
      />
      <ScreenShotSection
        imagesContainerClassName={styles.threeScreenshotSection}
        images={[
          "https://d2ec2ifrsjdn48.cloudfront.net/claro/image_10.png",
          "https://d2ec2ifrsjdn48.cloudfront.net/claro/image_9.png",
          "https://d2ec2ifrsjdn48.cloudfront.net/claro/image_8.png",
        ]}
      />
      <TwoColumnsSection
        headerSecondColumn={
          <SpecialText className={styles.bottomTextSpecial}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The
            New My Claro App is a self-service app for their mobile customers,
            using their branding as an interface. The app is available on the
            AppStore and Google Play Store, accessible only for Claro customers.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In
            2020, Fast Company named The New My Claro App winner in the Best
            Design of Latin America category.
          </SpecialText>
        }
      />
    </div>
    <TwoColumnsSection
      headerSecondColumn={
        <RightSideHeader>
          <p>Credits</p>
          <p>Carla Souza, Felipe Victor, Giovana Yahiro, Glauber Nicolau, Koji
          Takaki, Luciano Pouzada, Márcio Shiz, Victor Belinatti, Vinícius
          Benvenutti, and more.</p>
        </RightSideHeader>
      }
    />
  </Case>
);

export default Claro;
