import styles from "./styles.module.css";

const Header = () => (
  <header className={styles.header}>
    <div className={styles.title}>
      <p>
        Giovana Yahiro
        <br />
        Senior Designer
      </p>
    </div>
    <div className={styles.experience}>
      <p>Experience</p>
      <p>
        Sr. Interactive Designer
        <br />
        2023–C.
        <br />
        PORTO ROCHA
      </p>
      <p>
        Founding Designer
        <br />
        2020–2023
        <br />
        CREME
      </p>
      <p>
        Sr. Designer
        <br />
        2019–2020
        <br />
        Work & Co
      </p>
    </div>
    <div className={styles.contact}>
      <p>Contact</p>
      <p>
        E. <a href="mailto:giovanayahiro@gmail.com">giovanayahiro@gmail.com</a>
        <br />
        I. <a href="https://www.instagram.com/gyahiro/">@gyahiro</a>
      </p>
    </div>
    <button onClick={() => {
      window.scrollTo({top: document.querySelector('#about-section').offsetTop, behavior: 'smooth'})
    }} className={styles.about}>
      About
    </button>
  </header>
);

export default Header;
