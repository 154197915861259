import AssetSection from "../../components/AssetSection";
import Case from "../../components/Case";
import FullImageSection from "../../components/FullImageSection";
import RightSideHeader from "../../components/RightSideHeader";
import ScreenShotSection from "../../components/ScreenShotSection";
import SpecialText from "../../components/SpecialText";
import TwoColumnsSection from "../../components/TwoColumnsSection";

import styles from "./styles.module.css";

const Shakeshack = () => (
  <Case
    title="Shake Shack"
    type="Web Ordering"
    category="Food & Drink"
    date="2020"
  >
    <FullImageSection
      bleed
      centered
      asset="https://d2ec2ifrsjdn48.cloudfront.net/shakeshack/image_9.png"
      assetType="image"
      bgColor="#60AE2B"
      bottomContent={
        <SpecialText className={styles.bottomTextSpecial}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Shake Shack is a famous American fast-food chain that started out as a hot dog stand in New York City in 2001. Today, it has become a global phenomenon with 400+ locations globally and is expanding all the time. In 2020, the Work & Co team worked in close collaboration with the Shake Shack team, to redesign web ordering using hospitality to drive individual and catering orders, à la the in-Shack experience.
        </SpecialText>
      }
      horizontalAsset
    />
    <div className={styles.customSection}>
      <TwoColumnsSection
        headerSecondColumn={
          <RightSideHeader>
            <p>Framework</p>
            <p>
            We crafted a fresh menu experience that is both intuitive and easy to navigate. Guests can seamlessly browse the menu by scrolling through each category of items. Additionally, they have the option to quickly navigate to specific sections using the food menu category bar located across the top of the screen.
            </p>
          </RightSideHeader>
        }
        borderRadiusSecond={false}
      />
      <ScreenShotSection
        imagesContainerClassName={styles.threeScreenshotSection}
        images={[
          "https://d2ec2ifrsjdn48.cloudfront.net/shakeshack/image_1.png",
          "https://d2ec2ifrsjdn48.cloudfront.net/shakeshack/image_2.png",
          "https://d2ec2ifrsjdn48.cloudfront.net/shakeshack/image_3.png",
        ]}
      />
      <TwoColumnsSection
        className={styles.assetSectionWithMargin}
        firstColumn={
          <AssetSection
            asset="https://d2ec2ifrsjdn48.cloudfront.net/shakeshack/image_4.png"
            assetType="image"
            bgColor="var(--bg-black-secondary)"
            centered
          />
        }
      />
    </div>
    <TwoColumnsSection
      headerSecondColumn={
        <RightSideHeader>
          <p>Product page</p>
          <p>
          A guest can see more details about an item from the menu and make modifications by clicking through to the product’s detail page. From there, they can also conveniently add the product directly to their cart.
          </p>
        </RightSideHeader>
      }
      secondColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/shakeshack/video_1.mp4"
          assetType="video"
          bgColor="var(--bg-black-secondary)"
          centered
        />
      }
    />
    <TwoColumnsSection
      firstColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/shakeshack/image_5.png"
          assetType="image"
          bgColor="#6DB23F"
          centered
        />
      }
      headerSecondColumn={
        <RightSideHeader>
          <p>Proprietary cart</p>
          <p>
          We created a unique, fun and proprietary cart experience inspired by the trays found in their physical restaurants. The idea is that when a guest adds items to their cart or tray, these selections are represented by a thumbnail of the product, ensuring easy visibility.
          </p>
        </RightSideHeader>
      }
      secondColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/shakeshack/image_6.png"
          assetType="image"
          bgColor="#6DB23F"
          centered
        />
      }
    />
    <TwoColumnsSection
      headerSecondColumn={
        <RightSideHeader>
          <p>Order tracking</p>
          <p>
          We aimed to make order tracking simple and enjoyable, empowering users to effortlessly manage their preferences, from editing the order details to rescheduling the delivery.
          </p>
        </RightSideHeader>
      }
      secondColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/shakeshack/video_2.mp4"
          assetType="video"
          bgColor="var(--bg-black-secondary)"
          centered
        />
      }
    />
    <FullImageSection
      asset="https://d2ec2ifrsjdn48.cloudfront.net/shakeshack/image_7.png"
      centered
      bgColor="var(--bg-black-secondary)"
      assetType="image"
      topContent={
        <RightSideHeader>
          <p>
          Our primary aim was to create a design that offers a cohesive brand experience, connecting both physical and digital environments. Additionally, we focused on making sure the design is adaptable across a range of online platforms and interfaces.
          </p>
        </RightSideHeader>
      }
      topPosition="right"
      bottomContent={
        <RightSideHeader>
          <p>Credits</p>
          <p>
          Koji Takaki, Felipe Minuti, Giovana Yahiro, Diego Zambrano, Diogo Akio, Katie Cooper, and the internal Shake Shack team.
          </p>
        </RightSideHeader>
      }
      bottomPosition="right"
      horizontalAsset
      bleed
    />
  </Case>
);

export default Shakeshack;
