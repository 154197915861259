import styles from "./styles.module.css";

const About = () => (
  <div id="about-section" className={styles.container}>
    <div className={styles.content}>
      <div className={styles.header}>About</div>
      <div className={styles.text}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Giovana
        Yahiro is a senior designer focusing on products, experiences, and
        interfaces. As a designer, she is interested in contributing to
        projects that are conscientiously conceived and carefully crafted.
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For
        7+ years, she worked in cross-functional teams for digital agencies and
        product companies, translating ideas into meaningful products and
        experiences. Originally from Brazil, based in The Netherlands,
        working remotely as a senior interactive designer at PORTO ROCHA. Formerly
        at Work & Co, and Accenture Song.
      </div>
    </div>
    <a href="mailto:giovanayahiro@gmail.com" className={styles.getInTouchBtn}>Get in touch</a>
    <div className={styles.footer}>
      <div className={styles.date}>© 2024</div>
      <a className={styles.email} href="mailto:giovanayahiro@gmail.com">
        Mail
      </a>
      <a className={styles.instagram} href="https://www.instagram.com/gyahiro/">
        Instagram
      </a>
      <button
        className={styles.back}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        Back to top
      </button>
    </div>
  </div>
);

export default About;
