import styles from "./App.module.css";
import Claro from "./cases/Claro";
import Creme from "./cases/Creme";
import Shakeshack from "./cases/Shakeshack";
import About from "./components/About";
import Header from "./components/Header/Header";

function App() {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.cases}>
        <Creme />
        <Shakeshack />
        <Claro />
      </div>
      <About />
    </div>
  );
}

export default App;
