import Section from "../Section";
import cx from "classnames";

import styles from "./styles.module.css";

const FullImageSection = ({
  maximizeMobile,
  asset,
  bgColor,
  centered,
  assetType,
  bleed,
  imgBleed,
  innerImg,
  bottomPosition,
  bottomContent,
  topContent,
  topPosition,
  horizontalAsset
}) => (
  <div className={styles.container}>
    {topContent && (
      <div className={cx(styles.extraContent, styles.topContent)}>
        <div
          className={cx({ [styles.rightSideContent]: topPosition === "right" })}
        >
          {topContent}
        </div>
      </div>
    )}
    <Section
      bgColor={bgColor}
      className={{
        [styles.assetContainer]: centered,
        [styles.maximizeMobile]: maximizeMobile,
        [styles.horizontalAsset]: horizontalAsset
      }}
      bleed={bleed}
    >
      {assetType === "image" ? (
        <img
          className={cx([
            styles.img,
            { [styles.centered]: centered, [styles.imgBleed]: imgBleed },
          ])}
          src={asset}
        />
      ) : (
        <video
          playsInline
          className={cx([
            styles.img,
            { [styles.centered]: centered, [styles.imgBleed]: imgBleed },
          ])}
          muted
          controls={false}
          autoPlay
          loop
        >
          <source src={asset} type="video/mp4" />
        </video>
      )}
      {innerImg ? (
        <div className={styles.innerImg}>
          <img src={innerImg} />
        </div>
      ) : null}
    </Section>
    {bottomContent && (
      <div className={cx(styles.extraContent, styles.bottomContent)}>
        <div
          className={cx({
            [styles.rightSideContent]: bottomPosition === "right"
          })}
        >
          {bottomContent}
        </div>
      </div>
    )}
  </div>
);

export default FullImageSection;
