import cx from 'classnames'

import styles from './AssetSection.module.css';

const AssetSection = ({
  asset,
  assetType,
  bgColor,
  centered,
  border,
  noRadius
}) => {
  const media = assetType === 'image' ? (
    <img style={{border, borderRadius: noRadius ? 0 : null}} className={cx([styles.image, {[styles.centered]: centered}])} src={asset} />
  ) : (<video style={{border}} playsInline className={cx([styles.video, {[styles.centered]: centered}])} muted controls={false} autoPlay loop >
    <source src={asset} type="video/mp4" />
</video>)
  return (
    <div className={cx([styles.container, {[styles.assetContainerCentered]: centered}])} style={{backgroundColor: bgColor}}>
      {media}
    </div>
  )
}

export default AssetSection