import styles from './styles.module.css';

const Case = ({
  title,
  type,
  category,
  date,
  children
}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <p className={styles.title}>{title}</p>
      <p className={styles.type}>{type}</p>
      <p className={styles.category}>{category}</p>
      <p className={styles.date}>{date}</p>
    </div>
    <div className={styles.sections}>
      {children}
    </div>
  </div>
)

export default Case