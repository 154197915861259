import Section from "../Section";
import styles from "./styles.module.css";
import cx from "classnames";

const ScreenShotSection = ({
  imagesContainerClassName,
  bgColor = "var(--bg-black-secondary)",
  images,
  videos,
  center,
  className
}) => (
  <Section center={center} className={cx(styles.container, className)} bgColor={bgColor}>
    <div
      className={cx(imagesContainerClassName, styles.imagesContainer)}
    >
      {images ? images.map((img) => (
        <div className={styles.videoContainer}>
          <img src={img} key={img} />
        </div>
      )) : null}
      {
        videos ? videos.map((video) => (
          <div className={styles.videoContainer}>
            <video playsInline muted key={video} controls={false} autoPlay loop >
              <source src={video} type="video/mp4" />
            </video>
          </div>
        )) : null
      }
    </div>
  </Section>
);

export default ScreenShotSection;
