import cx from 'classnames';
import styles from "./styles.module.css";

const RightSideHeader = ({className, children}) => (
  <div className={cx(className, styles.container)}>
    {children}
  </div>
);

export default RightSideHeader;
