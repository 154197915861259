import AppleAwardsLogo from "../../components/AppleAwardsLogo";
import AssetSection from "../../components/AssetSection";
import Case from "../../components/Case";
import FullImageSection from "../../components/FullImageSection";
import RightSideHeader from "../../components/RightSideHeader";
import ScreenShotSection from "../../components/ScreenShotSection";
import SpecialText from "../../components/SpecialText";
import TwoColumnsSection from "../../components/TwoColumnsSection";

import styles from "./styles.module.css";
import cx from 'classnames';

const Creme = () => (
  <Case
    title="CREME"
    type="Product Ecosystem"
    category="Food & Hospitality"
    date="2020—2023"
  >
    <FullImageSection
      asset="https://d2ec2ifrsjdn48.cloudfront.net/creme/image_1.png"
      assetType="image"
      innerImg="https://d2ec2ifrsjdn48.cloudfront.net/creme/image_2.png"
      imgBleed
      bleed
      bottomContent={
        <SpecialText className={styles.bottomTextSpecial}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CREME
          is a food & hospitality, design, and tech company, attempting to
          rethink the industry. The CREME App is the first product of the
          business — the idea is that chefs from all over the world are filmed
          cooking what they love, and people subscribe to get unlimited access
          to their video recipes under a new recipe standard.
        </SpecialText>
      }
    />
    <div className={styles.customSection}>
      <TwoColumnsSection
        firstColumn={
          <video
            muted
            className={styles.img}
            controls={false}
            autoPlay
            playsInline
            loop
          >
            <source
              src="https://d2ec2ifrsjdn48.cloudfront.net/creme/video_1.mp4"
              type="video/mp4"
            />
          </video>
        }
        secondColumn={
          <div className={styles.rightSideText}>
            <p>
              For 2 years, I worked at CREME to help define, design and build
              the company and its first product: the CREME app, a platform to
              showcase smart recipes using high-quality content from chefs all
              over the world. Our goal was to inspire and build confidence, to
              give people skills, efficiency, and, ultimately, the pleasure of
              cooking.
            </p>
          </div>
        }
        borderRadiusSecond={false}
      />
      <ScreenShotSection
        imagesContainerClassName={styles.threeScreenshotSection}
        videos={[
          "https://d2ec2ifrsjdn48.cloudfront.net/creme/video_2.mp4",
          "https://d2ec2ifrsjdn48.cloudfront.net/creme/video_3.mp4",
          "https://d2ec2ifrsjdn48.cloudfront.net/creme/video_4.mp4",
        ]}
      />
    </div>
    <TwoColumnsSection
      headerSecondColumn={
        <RightSideHeader>
          <p>Framework</p>
          <p>
            The framework gives users total control of the content they’re
            consuming, while inspiring to cook in the comfort of their own
            kitchen.
          </p>
        </RightSideHeader>
      }
      firstColumn={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <video
            muted
            className={styles.img}
            controls={false}
            autoPlay
            playsInline
            loop
          >
            <source
              src="https://d2ec2ifrsjdn48.cloudfront.net/creme/video_5.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      }
      secondColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/creme/video_6.mp4"
          assetType="video"
          bgColor="#d9d185"
          centered
        />
      }
    />
    <TwoColumnsSection
      headerSecondColumn={
        <RightSideHeader>
          <p>A new recipe standard</p>
          <p>
            We created a simple formula that could break even the most complex
            recipes into simple steps, to make cooking fun, intuitive and
            accessible for most people. And the best part? All at your own pace.
          </p>
        </RightSideHeader>
      }
      secondColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/creme/video_7.mp4"
          assetType="video"
          bgColor="var(--bg-black-secondary)"
          centered
        />
      }
    />
    <TwoColumnsSection
      firstColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/creme/image_3.jpg"
          assetType="image"
          noRadius
        />
      }
      headerSecondColumn={
        <RightSideHeader>
          <p>Recipes and building blocks</p>
          <p>
          CREME allows chefs to break down recipes into components that can be combined with other components and products to create endless new recipes, unleashing exponential growth and efficient shooting sessions.
          </p>
        </RightSideHeader>
      }
      secondColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/creme/image_4.png"
          assetType="image"
          bgColor="#F1C167"
          centered
        />
      }
    />
    <TwoColumnsSection
      headerSecondColumn={
        <RightSideHeader>
          <p>Chef profiles</p>
          <p>
            Have you ever wondered what recipes famous chefs are actually
            cooking? Or what products and tools they're using? Profiles can
            serve as a hub of inspiration, and with CREME you're able to not
            only follow your friends and family, but that chef you like too.
          </p>
        </RightSideHeader>
      }
      secondColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/creme/image_5.png"
          assetType="image"
          bgColor="var(--bg-black-secondary)"
          centered
        />
      }
    />
    <TwoColumnsSection
      firstColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/creme/image_7.png"
          assetType="image"
          bgColor="var(--bg-black-secondary)"
          centered
        />
      }
      headerSecondColumn={
        <RightSideHeader>
          <p>Create memories</p>
          <p>
            Once you're done cooking, you can share the result with the entire
            CREME community. People are able to see what you cooked and react to
            it.
          </p>
        </RightSideHeader>
      }
      secondColumn={
        <AssetSection
          asset="https://d2ec2ifrsjdn48.cloudfront.net/creme/video_9.mp4"
          assetType="video"
          bgColor="var(--bg-black-secondary)"
          centered
        />
      }
    />
    <FullImageSection
      imgBleed
      bleed
      asset="https://d2ec2ifrsjdn48.cloudfront.net/creme/image_6.png"
      assetType="image"
    />
    <div className={styles.awardSection}>
      <div className={styles.awardSectionLogo}>
        <AppleAwardsLogo />
      </div>
      <SpecialText>
        2023 Finalist for the App Store Awards, in the<br /> Delight and Fun category.
      </SpecialText>
    </div>
    <FullImageSection
      centered
      bleed
      bgColor="var(--bg-black-secondary)"
      asset="https://d2ec2ifrsjdn48.cloudfront.net/creme/gif_1.gif"
      assetType="image"
      bottomContent={
            <SpecialText className={styles.bottomTextSpecial}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The
            CREME App redesigned the cooking experience to inspire, and
            assist people in building confidence in their kitchens. Available on{" "}
            <a
              href="https://apps.apple.com/za/app/creme-home-cooking-with-chefs/id1638361026"
              target="_blank"
              rel="noreferrer"
            >
              App Store
            </a>{" "}
            and{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.creme.app&hl=en_US&pli=1"
              target="_blank"
              rel="noreferrer"
            >
              Google Play Store
            </a>
            .
          </SpecialText>
      }
      bottomPosition="right"
    />
    <TwoColumnsSection
      headerSecondColumn={
        <RightSideHeader>
          <p>Credits</p>
          <p>
            Andreas Weiland, Diego Zambrano, Fernanda De Lamare, Giovana Yahiro,
            Marcos Lôndero, Marko Niciforovic, Nani Rodrigues, Rodrigo David,
            and the incredible team of CREME.
          </p>
        </RightSideHeader>
      }
    />
  </Case>
);

export default Creme;
