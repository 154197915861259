import Section from "../Section";
import styles from './styles.module.css';

import cx from 'classnames';

const TwoColumnsSection = ({className, firstColumn, secondColumn, headerFirstColumn, headerSecondColumn, borderRadiusFirst = true, borderRadiusSecond = true}) => (
  <Section className={cx(styles.section, {[styles.sectionWithSecondRow]: firstColumn || secondColumn, [styles.sectionWithHeader]: headerFirstColumn || headerSecondColumn}, className)}>
    {
      headerFirstColumn ? (
        <div className={styles.headerFirstColumn}>{headerFirstColumn}</div>
      ) : null
    }
    {
      headerSecondColumn ? (
        <div className={styles.headerSecondColumn}>{headerSecondColumn}</div>
      ) : null
    }
    {
      firstColumn ? (
        <div className={cx(styles.contentFirstColumn, {[styles.columnBorderRadius]: borderRadiusFirst})}>
          {firstColumn}
        </div>
      ) : null
    }
    {
      secondColumn ? (
        <div className={cx(styles.contentSecondColumn, {[styles.columnBorderRadius]: borderRadiusSecond})}>
          {secondColumn}
        </div>
      ) : null
    }
  </Section>
)

export default TwoColumnsSection;